import React from 'react'
import Layout from '../../../components/layout/Layout'

const LandscapeAndForestryIndex = () => {
  return (
    <Layout>
      <div id="landscape-and-forestry" className="page-content">
        <section id="landscape-and-forestry-section">
          <div className="inner">
            <h2>Landscape and Forestry</h2>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default LandscapeAndForestryIndex
